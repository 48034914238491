import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function OmniReleaseNotePage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>OMNI Software Release Note</h1>

          {/* Hyperlinks to each release note section */}
          <nav>
            <ul>
              <li>
                <a href="#MAI_ES_24_1204">MAI.ES.24.1204</a>
              </li>
              <li>
                <a href="#MAI_ES_24_0901">MAI.ES.24.0901</a>
              </li>
              <li>
                <a href="#MAI_ES_24_0719">MAI.ES.24.0719</a>
              </li>
              <li>
                <a href="#MAI_ES_24_0502">MAI.ES.24.0502</a>
              </li>
              <li>
                <a href="#MAI_ES_23_0731">MAI.ES.23.0731</a>
              </li>
            </ul>
          </nav>
          <h2>Installation Instructions</h2>
          <h3>By SD card</h3>
          <ul>
            <li>Download the software package from Mindtronic AI.</li>
            <li>Prepare an SD card with a capacity greater than 32 GB, formatted in FAT32.
              <ul>
                <li>To format the SD card as FAT32 in Windows 10 using the command prompt (for SD cards 32GB or smaller):</li>
                <li>Open Command Prompt as administrator (search for "cmd", right-click, and select "Run as administrator").</li>
                <li>Type <kbd>format /FS:FAT32 H:</kbd> and press Enter. <br />
                    <b>NOTE:</b> Replace <kbd>H</kbd> with the actual drive letter assigned to your SD card in Windows.
                </li>
                <li>At the prompt, press Enter again to start the formatting process. Be aware this will erase all data on the SD card.</li>
                <li>For SD cards larger than 32 GB, you have two options:</li>
                <ul>
                  <li><b>Option 1: Format using the OMNI device:</b></li>
                  <li>Insert the SD card into the OMNI device.</li>
                  <li>When you hear the prompt "Please format the SD card", select "Continue" to proceed with the formatting.</li>
                  <li><b>Option 2 (Up to 1 TB): Format using the 'diskpart' utility on a Windows PC:</b></li>
                  <li><b>Step 1.</b> Right-click on the Windows icon, select "Search", and type: <kbd>command prompt</kbd>.</li>
                  <li>Right-click on "Command Prompt" and select "Run as administrator".</li>
                  <li><b>Step 2.</b> Type <kbd>diskpart</kbd> in Command Prompt and press Enter.</li>
                  <li><b>Step 3.</b> Type the following commands in DiskPart, pressing Enter after each one:
                    <ul>
                      <li><kbd>list disk</kbd></li>
                      <li><kbd>select disk #</kbd> (Replace # with the number corresponding to your SD card).</li>
                      <li><kbd>list volume</kbd></li>
                      <li><kbd>select volume #</kbd> (Replace # with the volume number of the partition on your SD card that you want to format).</li>
                      <li><kbd>format fs=fat32 quick</kbd></li>
                    </ul>
                    </li>
                    <li>
                        <b>Warning:</b> The <kbd>format</kbd> command without "fs=fat32 quick" will perform a full format, erasing all data sector by sector and making data recovery impossible. Double-check that you have entered the correct commands before pressing Enter.
                    </li>
                  <li><b>Step 4.</b> Type <kbd>exit</kbd> and press Enter to close DiskPart when it reports the volume has been successfully formatted.</li>
                </ul>
              </ul>
            </li>
            <li>Place the file named SigmastarUpgradeSD.bin into the root directory of the SD card.</li>
            <li>Insert the SD card into the OMNI device and then reboot it.</li>
          </ul>
          <h3>By OTA</h3>
          <ul>
            <li>
              Compile a list of the Device IDs that are intended for the update.
            </li>
            <li>
              Power on your OMNI device and ensure it maintains a stable
              internet connection throughout the update process.
            </li>
            <li>
              Remain patient until the device displays a message. It takes about
              20 minutes.
            </li>
            <li>
              Carefully follow the directions shown on the OMNI device&apos;s
              screen.
            </li>
          </ul>

          <h2>Support</h2>
          <p>
            For any issues encountered during or after the installation, please
            contact our technical support team.
          </p>

          <h2 id="MAI_ES_24_1204">MAI.ES.24.1204</h2>
          <h3>Release Date</h3>
          <p>2024-12-04</p>

          <h3>Overview</h3>
          <p>
            This release introduces several enhancements aimed at improving performance.
          </p>
          <h3>Enhancements</h3>
          <ul>
            <li>
              Improved I2C reading performance for G-sensors.
            </li>
            <li>
              Enhanced auto-calibration performance.
            </li>
            <li>
              Introduced a new model for face detection to improve accuracy.
            </li>
          </ul>

          <h3>Bug Fixes</h3>
          <ul>
            <li>
              Resolved a potential crash issue during process communications.
            </li>
            <li>
              Fixed a rare config file corruption issue.
            </li>
            <li>
              Addressed instability in the Acc signal that could cause the device to freeze.
            </li>
          </ul>
          <br />
          <h2 id="MAI_ES_24_0901">MAI.ES.24.0901</h2>
          <h3>Release Date</h3>
          <p>2024-09-01</p>

          <h3>Overview</h3>
          <p>
            This release introduces several enhancements and new features aimed
            at improving performance and user control.
          </p>

          <h3>New Features</h3>
          <h4>Web</h4>
          <ul>
            <li>Enhanced UI layout</li>
          </ul>
          <h4>OMNI</h4>
          <ul>
            <li>
              Internal server API for downloading video files from the SD card.
            </li>
          </ul>

          <h3>Bug Fix</h3>
          <ul>
            <li>
              OMNI cannot display the Wi-Fi scanning result if the SSID (Service
              Set Identifier) is too long.
            </li>
            <li>Constrain the sound volume to not exceed 80.</li>
            <li>Fix the typo in the UI.</li>
          </ul>

          <br />
          <h2 id="MAI_ES_24_0719">MAI.ES.24.0719</h2>
          <h3>Release Date</h3>
          <p>2024-07-19</p>

          <h3>Overview</h3>
          <p>
            This release introduces several enhancements and new features aimed
            at improving performance and user control.
          </p>

          <h3>New Features</h3>
          <ul>
            <li>
              <strong>Data Uploading to 3rd party&apos;s Cloud:</strong> This
              new feature enables seamless data uploading to 3rd party&apos;s
              cloud, ensuring efficient data management and accessibility.
            </li>
          </ul>

          <h3>Enhancements</h3>
          <ul>
            <li>
              <strong>Fine-Tuning of DMS Parameters:</strong> The parameters for
              detecting DMS events have been fine-tuned, resulting in more
              accurate and reliable detection. It includes face detection and
              phone talking detection.
            </li>
          </ul>

          <h3>Additional Notes</h3>
          <ul>
            <li>
              Before updating, ensure that all critical data is backed up.
            </li>
            <li>When updating, do not power off the device.</li>
            <li>
              <strong>
                Please install the DMC at the center of the cockpit, aligned
                centrally towards the driver.
              </strong>
            </li>
          </ul>
          <br />
          <h2 id="MAI_ES_24_0502">MAI.ES.24.0502</h2>
          <h3>Release Date</h3>
          <p>2024-05-02</p>

          <h3>Overview</h3>
          <p>
            This release introduces several enhancements and new features aimed
            at improving performance and user control. Key updates include
            support for remote parameter adjustment and a new auto-calibration
            feature to compensate for post-installation errors.
          </p>

          <h3>New Features</h3>
          <ul>
            <li>
              <strong>Remote Parameter Adjustment:</strong> This remote fine
              tune feature offers you unparalleled flexibility and control,
              allowing you to adjust the sensitivity thresholds of your device
              on the web. Tailor how your device responds to different events,
              ensuring it operates exactly as needed for your specific
              conditions. Discover and manage these settings easily through our
              online platform at{' '}
              <a href="https://cloud.mindtronicai.com/tune">
                https://cloud.mindtronicai.com/tune
              </a>
              .
            </li>
            <li>
              <strong>Auto Calibration:</strong> Our advanced auto-calibration
              feature is designed to adjust the installation parameters for
              Advanced Driver Assistance Systems (ADAS) and Driver Monitoring
              Systems (DMS) based on initial settings. Even if there are slight
              changes in orientation post-installation, it can effectively
              recalibrate to ensure optimal performance without manual
              intervention if the device is slightly moved. This ensures your
              systems are always operating at their best, regardless of minor
              shifts or alterations.
            </li>
            <li>
              <strong>DMS Event Clip Enable/Disable Switch:</strong> Users can
              now enable or disable the recording of DMS (Driver Monitoring
              System) event clips based on their preferences and requirements,
              offering better control over privacy and data storage.
            </li>
          </ul>

          <h3>Enhancements</h3>
          <ul>
            <li>
              <strong>Fine-Tuning of Harsh Driving Event Parameters:</strong>{' '}
              The parameters for detecting harsh driving events have been
              fine-tuned, resulting in more accurate and reliable detection. It
              includes Harsh Acceleration, Harsh Brake, and Harsh Turn.
            </li>
            <li>
              <strong>Extended Trajectory Storage:</strong> We&apos;ve upgraded
              our existing feature to significantly increase the storage
              capacity for trajectory data. Now, even when your device is
              offline, it can store much more data than before, allowing for
              extended periods of uninterrupted data recording. This ensures
              that no important trajectory information is missed, regardless of
              internet connectivity.
            </li>
          </ul>

          <h3>Usage of Fine Tune Tool</h3>
          <ol>
            <li>
              After signing in to our cloud service, go to{' '}
              <a href="https://cloud.mindtronicai.com/tune">
                https://cloud.mindtronicai.com/tune
              </a>
              .
            </li>
            <li>Modify the configuration value in the text field.</li>
            <li>Press the corresponding save button.</li>
            <li>Reboot the OMNI device, ensuring the internet is connected.</li>
            <li>If the configuration is related to DMS, reboot again.</li>
          </ol>

          <h3>Additional Notes</h3>
          <ul>
            <li>
              Before updating, ensure that all critical data is backed up.
            </li>
            <li>When updating, do not power off the device.</li>
            <li>
              Please install the DMC at the center of the cockpit, aligned
              centrally towards the driver.
            </li>
          </ul>
          {/* New Release Note */}
          <br />
          <h2 id="MAI_ES_23_0731">MAI.ES.23.0731</h2>
          <h3>Release Date</h3>
          <p>2023-07-31</p>

          <h3>Overview</h3>
          <p>
            The OMNI full package is an integrated solution that combines the
            OMNI device, fleet management web portal, and fleet management app.
            Administrators can manage drivers and receive incident notifications
            via the web and app, while drivers can accept dispatch tasks, clock
            in, and submit expense reports via the app. OMNI installers can also
            configure and calibrate the OMNI device using the app.
          </p>

          <h3>Version</h3>
          <ul>
            <li>
              <strong>OMNI:</strong> MAI.ES.23.0731.1
            </li>
            <li>
              <strong>Web:</strong> 0.9.0
            </li>
            <li>
              <strong>App:</strong> 1.1.0
            </li>
          </ul>

          <h3>New Features</h3>
          <h4>Web</h4>
          <ul>
            <li>Comprehensive fuel usage analysis</li>
            <li>Goods dispatch management</li>
            <li>Geofencing functionality</li>
            <li>Anomaly notification management settings</li>
          </ul>

          <h4>App</h4>
          <ul>
            <li>Driver fuel/maintenance expense reports</li>
            <li>Driver clock-in functionality</li>
            <li>Driver task acceptance/reporting page</li>
            <li>New task notifications</li>
            <li>Fleet anomaly notifications</li>
            <li>SI configuration page</li>
            <li>Remote ADAS calibration functionality</li>
          </ul>

          <h3>Enhancements</h3>
          <h4>Web</h4>
          <ul>
            <li>Improved statistical pages</li>
            <li>UI enhancements for better usability</li>
          </ul>

          <h4>App</h4>
          <ul>
            <li>Updated fleet management interface</li>
            <li>UI enhancements for better usability</li>
          </ul>

          <h3>Bug Fixes</h3>
          <h4>OMNI</h4>
          <ul>
            <li>
              DMC indicator in the bottom-right corner may not display correct
              information when the DMC is plugged/unplugged
            </li>
            <li>
              Simultaneous DVR playback and event recording may cause stream
              playback failure on mobile
            </li>
            <li>Road vibrations trigger harsh turn events</li>
            <li>Fatigue events do not trigger</li>
            <li>
              Closing and reopening DVR-F results in a black screen when playing
              the first DVR-F playback on OMNI
            </li>
            <li>Unable to update bumper information during ADAS calibration</li>
            <li>Switching Wi-Fi hotspots causes connection issues</li>
          </ul>

          <h4>Web</h4>
          <ul>
            <li>Dispatch list cannot update completion dates</li>
            <li>Completion dates in the dispatch list display as blank</li>
            <li>Growth rate bubbles in Event Trend do not display</li>
            <li>Event sidebar overlaps when the driver name is too long</li>
          </ul>

          <h4>App</h4>
          <ul>
            <li>
              After any account logs into the app, the app automatically
              switches to fleet management mode upon reopening
            </li>
            <li>Dispatch list cannot update completion dates</li>
            <li>Completion dates in the dispatch list display as blank</li>
            <li>Incorrect time display on mobile devices</li>
          </ul>

          <h3>Known Issues</h3>
          <ul>
            <li>
              mai_hal or event center sometimes crashes, causing the device to
              restart
            </li>
            <li>HMW false positives triggered in underground parking lots</li>
            <li>
              Due to insufficient data, fuel analysis calculation formulas
              cannot be derived, and the web displays hypothetical results based
              on random data
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OmniReleaseNotePage;
