import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './css/ContentPage.css'; // Ensure this is correctly imported
import './css/TextStyle.css'; // Ensure this is correctly imported
/*eslint-disable */

function Introduction({ user }) {
  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <h1>Welcome to the Mindtronic AI Portal </h1>
      </div>
      {/* <h2>Start with the basics</h2>
      <div className="row">
        <a href="#choose-product" className="green-color-element">
          <h2>Quick Start Tutorial</h2>
          <p>Get start using Mindtronic AI&apos;s product right now.</p>
        </a>
        <a href="#our-technology" className="blue-color-element">
          <h2>Finding our solutions</h2>
          <p>
            Discover Mindtronic AI&apos;s product offerings that can assist you.
          </p>
        </a>
      </div> */}
      <h2 id="choose-product">Choose the product</h2>
      <div className="row">
        <a href="/omni/overview" className="blue-color-element">
          <h2>OMNI</h2>
          <p>
            The all-in-one product encompasses DMS, ADAS, DVR, along with a
            comprehensive cloud-based fleet management system and a mobile app.
          </p>
        </a>
        <div className="gray-color-element">
          <h2>HORUS</h2>
          <p>
            The standalone DMS, along with a comprehensive cloud-based fleet
            management system and a mobile app.
          </p>
        </div>
        <a href="/Noah/overview" className="green-color-element">
          <h2>Noah</h2>
          <p>
            Noah was designed from the ground up to be easily installed and used
            to get your HMI up and running quickly without software background
          </p>
        </a>
      </div>
      <h2>Support</h2>
      <div className="row">
        <div className="element">
          <h2>Can&apos;t find out what you want?</h2>
          <p>
            If you still have a question, don&apos;t hesitate to contact
            Mindtronic AI&apos; support by sending{' '}
            <a href="mailto:support@mindtronicai.com">email</a>. Mindtronic AI can
            assist you - wherever you are, whenever you need it.
          </p>
        </div>
      </div>
    </div>
  );
}

// Define prop types
Introduction.propTypes = {
  user: PropTypes.string.isRequired,
};

export default Introduction;
