import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
// import DownloadTable from './DownloadTable';
/*eslint-disable */

// const documents = [
//   {
//     name: 'Pre-released OMNI Android app',
//     description: 'The pre-release version of OMNI Android App',
//     downloadUrl:
//       'https://api.cloud.mindtronicai.com:7001/download/omni_17704.apk',
//   },
//   // ... more documents
// ];

function OmniPackageAppPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Mobile App</h1>
          <h2>Introduction</h2>
          <p>
            Stay connected with your fleet anytime, anywhere with our mobile
            app. Designed for efficiency and ease of use on-the-go, our app
            brings the power of cloud-based fleet management to your fingertips.
          </p>
          <p>
            Download the mobile app using the QR code and sign in with your
            existing account. The sign in account and password are the same as
            accessing this portal. The app enables you to stay updated on the
            fleet&apos;s current status, communicate and monitor the drivers in
            real-time, and receive notifications for any abnormal events.{' '}
          </p>
          <div className="appCenterContent">
            <div className="centerContent">
              <img
                src="/images/android_qrcode.png"
                alt="First"
                className="centeredImage"
              />
              <img
                src="/images/android_store.png"
                alt="Second"
                className="centeredImageStore"
              />
            </div>
            <div className="centerContent">
              <img
                src="/images/ios_qrcode.png"
                alt="First"
                className="centeredImage"
              />
              <img
                src="/images/ios_store.png"
                alt="Second"
                className="centeredImageStore"
              />
            </div>
          </div>
          {/* <p>
              You can also download the latest &nbsp;
              <a href="https://api.cloud.mindtronicai.com:7001/download/omni_17704.apk">
                Pre-released Android app
              </a>
              &nbsp; in resource table.
          </p>
          <h3>Steps to Download and Install the Pre-Released Android APK</h3>
          <ol>
            <li>
              <strong>Enable App Installation from Unknown Sources:</strong>
              <ul>
                <li>Please check the images below this section for this step.</li>
                <li>Open your device's <strong>Settings</strong>.</li>
                <li>Navigate to <strong>Security</strong> or <strong>Privacy</strong> (the name may vary depending on your device).</li>
                <li>Enable <strong>Install Unknown Apps</strong> or <strong>Unknown Sources</strong> for the browser or applications you used to download the APK.</li>
              </ul>
            </li>
            <li>
              <strong>Access the Link:</strong>
              <ul>
                <li>Open the browser you select in the previous step on your Android device.</li>
                <li>Tap on the provided link to download the APK file.</li>
              </ul>
            </li>
            <li>
              <strong>Start the Download:</strong>
              <ul>
                <li>If prompted, confirm the download by selecting <strong>"Download"</strong> or <strong>"OK"</strong>.</li>
                <li>Wait for the APK file to download completely. You can check the progress in the notification bar.</li>
              </ul>
            </li>
            <li>
              <strong>Locate the APK File:</strong>
              <ul>
                <li>Open the <strong>File Manager</strong> app or go to your device’s <strong>Downloads</strong> folder.</li>
                <li>Look for the downloaded APK file (usually named after the app or with an <strong>.apk</strong> extension).</li>
              </ul>
            </li>
            <li>
              <strong>Install the APK:</strong>
              <ul>
                <li>Tap on the APK file.</li>
                <li>If prompted, review the permissions and select <strong>"Install"</strong>.</li>
                <li>Wait for the installation to complete.</li>
              </ul>
            </li>
            <li>
              <strong>Open the App:</strong>
              <ul>
                <li>Once installed, tap <strong>"Open"</strong> to launch the app directly.</li>
                <li>Alternatively, you can find the app icon in your app drawer.</li>
              </ul>
            </li>
          </ol> */}
          {/* <div className="appCenterContent">
            <img
              src="/images/android_settings_1.jpg"
              alt="Second"
              className="centeredImageStore"
            />
            <img
              src="/images/android_settings_2.jpg"
              alt="Second"
              className="centeredImageStore"
            />
          </div> */}
          <h2>Key Features and Functions:</h2>
          <p>
            Our mobile app brings a suite of powerful tools to your fingertips,
            ensuring you stay in control of your fleet, no matter where you are.
            Key features include:
          </p>
          <h3>Notification</h3>
          <p>
            Our Notification system within the mobile app is a key tool in
            maintaining the safety and efficiency of your fleet. It is designed
            to keep you informed with immediate and relevant alerts, ensuring
            you&apos;re always aware of critical events affecting your fleet.
            Key features of this system include:
          </p>
          <ul>
            <li>
              <strong>Immediate Alerts for Abnormal Events:</strong> Receive
              instant notifications for any unusual occurrences, such as
              vehicles deviating from their assigned routes. This feature
              ensures you are promptly informed of any potential issues.
            </li>
            <li>
              <strong>Timely and Relevant Updates:</strong> Our system provides
              updates that are both timely and pertinent to your fleet&apos;s
              operations. Stay updated with the most critical information when
              it matters the most.
            </li>
          </ul>
          <p>
            By utilizing the Notification system, you can respond quickly to any
            unforeseen events, enhancing the overall safety and operational
            efficiency of your fleet.
          </p>
          <h3>Status & Positioning</h3>
          <p>
            The Status & Positioning feature in our mobile app provides a
            real-time, comprehensive overview of your entire fleet. This feature
            is crucial for maintaining up-to-date information on the location
            and status of all fleet members. Key benefits of this system
            include:
          </p>
          <ul>
            <li>
              <strong>Real-Time Location Updates:</strong> Receive immediate
              information on the whereabouts of every fleet member. This ensures
              you are constantly aware of their precise location at any given
              moment.
            </li>
            <li>
              <strong>Instant Status Insights:</strong> Gain insights into the
              operational condition of your fleet vehicles. This feature
              provides critical information about the status of each vehicle,
              allowing for effective management and quick response to any
              situational changes.
            </li>
          </ul>
          <p>
            By leveraging the Status & Positioning feature, you can enhance your
            situational awareness and operational control, ensuring efficient
            management and coordination of your fleet.
          </p>
          <h3>Live Streaming</h3>
          <p>
            The Live Streaming feature in our mobile app is a powerful tool for
            real-time oversight of your fleet operations. It offers on-demand
            video streaming, giving you a live view of your fleet’s activities.
            This feature is essential for gaining immediate insights and
            maintaining close surveillance. Key aspects of this system include:
          </p>
          <ul>
            <li>
              <strong>Real-Time Video Streaming:</strong> Access live video
              feeds of your fleet at any time. This instant streaming capability
              allows you to monitor operations as they happen, providing a
              direct window into day-to-day activities.
            </li>
            <li>
              <strong>Invaluable Operational Insights:</strong> Gain crucial
              insights into the operational dynamics of your fleet. Live
              streaming helps in assessing situations quickly and making
              informed decisions based on current events and activities.
            </li>
          </ul>
          <p>
            Utilizing the Live Streaming feature empowers you with enhanced
            visibility and control over your fleet, ensuring that you are always
            connected to and informed about ongoing operations.
          </p>
          <h3>Dispatching</h3>
          <p>
            The Dispatching feature in our mobile app revolutionizes the way you
            manage and oversee cargo dispatch assignments. This tool is
            specifically designed to streamline the process of logistics
            coordination, providing a user-friendly interface for efficient
            dispatch management. Key features and benefits of this system
            include:
          </p>
          <ul>
            <li>
              <strong>Efficient Management of Dispatch Assignments:</strong>{' '}
              Easily manage and assign cargo dispatch tasks directly through the
              app. This functionality enables efficient planning and
              distribution of logistics tasks.
            </li>
            <li>
              <strong>Real-Time Tracking and Oversight:</strong> Maintain
              continuous oversight by tracking cargo dispatch assignments in
              real-time. This feature ensures transparency and provides timely
              updates throughout the entire delivery process.
            </li>
          </ul>
          <p>
            By utilizing the Dispatching feature, you gain a more effective and
            streamlined approach to logistics management, enhancing the
            efficiency and reliability of your cargo dispatch operations.
          </p>
          <h3>Clock In/Clock Out</h3>
          <p>
            The Clock In/Clock Out feature in our mobile app is an essential
            tool for managing driver working hours and ensuring compliance with
            time-tracking regulations. This feature provides a simple and
            effective way for drivers to log their working hours, streamlining
            the process of time management within your fleet. Key
            functionalities and benefits include:
          </p>
          <ul>
            <li>
              <strong>Easy Time Logging:</strong> Drivers can effortlessly clock
              in at the start of their shift and clock out at the end, using the
              mobile app. This ease of use ensures accurate time tracking with
              minimal hassle.
            </li>
            <li>
              <strong>Streamlined Damage Reporting:</strong> In case of any
              noticeable damage, the photo feature enables immediate reporting,
              simplifying the process of damage assessment and repair
              management.
            </li>
          </ul>
          <p>
            Implementing the Clock In/Clock Out feature enhances the overall
            management of driver working hours, contributing to the efficient
            and compliant operation of your fleet.
          </p>
          <p>
            These features, and more, are readily available through our mobile
            app, providing you with the tools and information needed to
            effectively manage your fleet from anywhere.
          </p>
        </div>
      </div>
      {/* The table for download. */}
      {/* <h2>Resources</h2>
      <DownloadTable documents={documents} /> */}
    </div>
  );
}
export default OmniPackageAppPage;
